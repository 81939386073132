

































































import { Component, Vue } from "vue-property-decorator";
import { authorizationService } from "@/shared/services/AuthorizationService";
import { Login } from "@/shared/dtos/login";

@Component
export default class LogearOtp extends Vue {
  public otpArray: string[] = ["", "", "", "", "", ""];
  //private secretKey: string = "";
  public mensaje: string | null = null;
  public mensajeTipo: string = "info";
  public login: Login = new Login();

  get isOtpComplete() {
    return this.otpArray.every((digit) => digit !== "");
  }

  created() {
    //this.secretKey = this.$route.params.secretKey;
    this.login.UserName = this.$route.params.UserName;
    this.login.Pwd = this.$route.params.Pwd;
    this.login.sercret_opt = this.$route.params.sercret_opt;
    this.login.Name = this.$route.params.Name;
  }

  async validarOtp() {
    const otp = this.otpArray.join(""); // Convertir los 6 dígitos en un solo string

    try {
      const response = await authorizationService.validarOtp({
        secretKey: this.login.sercret_opt,
        otp: otp,
        UserName: this.login.UserName,
        Pwd: this.login.Pwd,
      });

      if (response.valid) {
        this.mensaje = "Código OTP válido. Autenticación exitosa.";
        this.mensajeTipo = "success";
        setTimeout(() => {
          //response.loginDto
          console.log("response.loginDto", response.loginDto);
          authorizationService.onLogin(response.loginDto);

          if (response.loginDto.Success) {
            this.$router.push("/");
          }
        }, 1000);
      } else {
        this.mensaje = "Código OTP incorrecto. Inténtalo nuevamente.";
        this.mensajeTipo = "error";
      }
    } catch (error) {
      console.error("Error validando OTP:", error);
      this.mensaje = "Error en la validación del código OTP.";
      this.mensajeTipo = "error";
    }
  }

  focusNext(index: number, event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.value && index < this.otpArray.length - 1) {
      const nextInput = (this.$refs.otpInputs as HTMLInputElement[])[index + 1];
      if (nextInput) {
        nextInput.focus();
      }
      //nextInput?.focus();
    }
  }
}
